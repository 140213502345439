import React, { SetStateAction } from 'react'
import { useTheme } from 'styled-components'

import { useNonInitialEffect } from 'hooks/utils'
import useGetStores from 'hooks/useGetStores'

import { baseURLPixAnyBankCustomerUserValidation, post } from 'services/api'

import { getBanks } from 'helpers/banks'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import {
  applyAccountMask,
  applyCpfCnpjMask,
  applyPhoneMask,
} from 'helpers/masks'
import { showToast } from 'components/Toast'

import * as s from '../styles'
import { formatDate } from 'helpers/date'
import { FormProps } from '.'
import {checkUserClaim} from "../../../helpers/claims";
import {UserSessionInfo} from "../../../store/modules/auth/types";

const TbanksCompanyForm: React.FC<{
  nextStep: () => void
  previousStep: () => void
  isFrom: string
  pixFormState: [FormProps, React.Dispatch<SetStateAction<FormProps>>]
  feeTaxes?: []
}> = ({ nextStep, previousStep, isFrom, pixFormState, feeTaxes }) => {
  const { stores, isLoading, loadStores } = useGetStores()
  const [form, setForm] = pixFormState
  const [selectedCnpj, setSelectedCnpj] = React.useState({ id: -1, name: '' })
  const [CNPJOptions, setCNPJOptions] = React.useState([])
  const [selectedBank, setSelectedBank] = React.useState({ id: -1, name: '' })
  const banks = getBanks()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map((bank, index) => {
      return {
        id: index,
        text: `${bank.code} - ${bank.name}`,
        onClick: () => {
          setSelectedBank({ id: index, name: `${bank.code} - ${bank.name}` })
        },
      }
    })
  const [company, setCompany] = React.useState({
    name: '',
    phone_number: '',
    created_at: '',
    email: '',
    document_number: '',
  })
  const [destinationAccount, setDestinationAccount] = React.useState({
    bank: '',
    branch: '',
    account: '',
  })

  const [isValidateCustomerUserLoading, setIsValidateCustomerUserLoading] =
    React.useState(false)
  const theme = useTheme()
  const [feeValue, setFeeValue] = React.useState<number | string>(feeTaxes[isFrom]?.fee_value ?? "");
  const [errorFeeValue, setErrorFeeValue] = React.useState(false);

  const validateCustomerUser = async () => {
    setIsValidateCustomerUserLoading(true)
    const currentUser = JSON.parse(
      getValueFromLocalStorage('currentUserSession')
    )
    setForm({
      ...form,
      company: {
        ...company,
        document_number: selectedCnpj.name,
      },
      destinationAccount: {
        ...destinationAccount,
        bank: selectedBank.name,
      },
    })
    await post(`${baseURLPixAnyBankCustomerUserValidation}`, {
      cnpj: selectedCnpj.name.replace(/[./-]+/g, ''),
      user_id: currentUser.id,
      pix_any_bank_identifier: {
        order_type: isFrom === 'original' ? 'cob' : 'cobv',
        psp_provider: isFrom,
      },
    })
      .then(() => {
        nextStep()
      })
      .catch((err) => {
        if (window['env']['name'] !== 'production') {
          nextStep()
        }

        if (
          JSON.parse(err.request.response).message ===
          'User is not the company owner'
        ) {
          showToast({
            type: 'error',
            message: `O usuário ${currentUser.name} não consta no QSA do CNPJ selecionado. Entre em contato com o representante do seu sistema de PDV ou com o time comercial da Shipay para mais detalhes`,
          })
        } else {
          showToast({
            type: 'error',
            message: `${JSON.parse(err.request.response).message}`,
          })
        }
      })
      .finally(() => {
        setIsValidateCustomerUserLoading(false)
      })
  }

  const handleFeeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value)
    const value = isNaN(newValue) ? "" : newValue;

    setFeeValue(value);

    if (value !== "" && value <= feeTaxes[isFrom].minimum_fee_value) {
      setErrorFeeValue(true);
    } else {
      setErrorFeeValue(false);
    }
  };

  const currentUserSession: UserSessionInfo = JSON.parse(
    getValueFromLocalStorage('currentUserSession')
  )
  const hasPixAnybankFeeClaim = checkUserClaim(
    currentUserSession?.user_claims,
    'panel_pages_pos_vendor'
  )

  React.useEffect(() => {
    loadStores({ limit: '200', offset: '0', active: true })
  }, [])

  React.useEffect(() => {
    if (form.company?.document_number && CNPJOptions.length > 0) {
      setSelectedBank({
        id: banks.find((bank) => bank.text === form.destinationAccount.bank).id,
        name: form.destinationAccount.bank,
      })
      setSelectedCnpj({
        id: CNPJOptions.find(
          (cnpj) => cnpj.text === form.company.document_number
        ).id,
        name: form.company.document_number,
      })
      setCompany({
        ...form.company,
      })
      setDestinationAccount({ ...form.destinationAccount })
    }
  }, [form, CNPJOptions])

  useNonInitialEffect(() => {
    setCNPJOptions([
      ...stores.data
        .filter((store) => store.cnpj_cpf.length > 11 && store.active)
        .map((store, index) => {
          return {
            id: index,
            text: applyCpfCnpjMask(store.cnpj_cpf),
            onClick: () => {
              setSelectedCnpj({
                id: index,
                name: applyCpfCnpjMask(store.cnpj_cpf),
              })
            },
          }
        }),
    ])
  }, [stores])

  return (
    <>
      <s.ModalWrapper>
        <s.HeaderWrapper>
          <s.Text bold type="headline">
            Insira os dados da conta bancária de destino e de sua empresa.
          </s.Text>
        </s.HeaderWrapper>
        <s.CompanyFormWrapper>
          <s.BankAccountFeeContainer>
            <s.BankAccountTbanks>
              <s.Text bold type="headline">
                Conta bancária destino
              </s.Text>
              <s.SearchSelect
                label="Banco"
                options={banks}
                selectedState={[selectedBank, setSelectedBank]}
              />
              <s.InputText
                width="273"
                maxLength={4}
                label="Agência (sem dígito)"
                value={destinationAccount.branch}
                onChange={(e) => {
                  setDestinationAccount({
                    ...destinationAccount,
                    branch: e.target.value,
                  })
                }}
              />
              <s.InputText
                width="273"
                label="Conta"
                onChange={(e) => {
                  setDestinationAccount({
                    ...destinationAccount,
                    account: applyAccountMask(e.target.value),
                  })
                }}
                value={destinationAccount.account}
              />
            </s.BankAccountTbanks>
            {hasPixAnybankFeeClaim ? (
              <s.FeeValueTbanks>
                <s.Text bold type="headline">
                  Tarifa da Conta
                </s.Text>
                <s.InputText
                  width="273"
                  label="Valor"
                  type={"number"}
                  value={feeValue.toString()}
                  onChange={handleFeeValueChange}
                  error={errorFeeValue}
                  inputProps={{ min: feeTaxes[isFrom]?.minimum_fee_value ?? 0 }}
                />
              </s.FeeValueTbanks>
            ) : (
              <></>
            )}
          </s.BankAccountFeeContainer>

          <s.CompanyTbanks>
            <s.Text bold type="headline">
              Empresa
            </s.Text>
            <s.InputText
              width="273"
              label="Nome da Empresa"
              value={company.name}
              onChange={(e) => {
                setCompany({ ...company, name: e.target.value })
              }}
            />
            <s.InputText
              width="273"
              label="Telefone"
              value={company.phone_number}
              onChange={(e) => {
                if (e.target.value.length < 16) {
                  setCompany({
                    ...company,
                    phone_number: applyPhoneMask(e.target.value),
                  })
                }
              }}
            />
            <s.InputText
              width="273"
              label="Data de abertura"
              placeholder="dd/mm/yyyy"
              value={company.created_at}
              onChange={(e) => {
                setCompany({
                  ...company,
                  created_at: formatDate(e.target.value),
                })
              }}
            />
            <s.InputText
              width="273"
              label="E-mail"
              value={company.email}
              onChange={(e) =>
                setCompany({ ...company, email: e.target.value })
              }
            />
            <s.Select
              label="CNPJ da Conta"
              isLoading={isLoading}
              options={CNPJOptions}
              selected={selectedCnpj}
            />
            <s.SubText type="tag">
              Essa conta de destino precisa ser uma conta Pessoa Jurídica de um
              CNPJ já cadastrado{' '}
              {theme.domain === 'Shipay' ? 'na Shipay' : 'no Conexão Itaú'}.
            </s.SubText>
          </s.CompanyTbanks>
        </s.CompanyFormWrapper>
      </s.ModalWrapper>
      <s.Footer>
        <s.LeftButtonAnybank
          onClick={() => {
            previousStep()
          }}
          color="whitedetailed"
          width="150px"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text bold color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          disabled={
            selectedBank.name === '' ||
            selectedCnpj.name === '' ||
            isValidateCustomerUserLoading
          }
          onClick={() => {
            validateCustomerUser()
          }}
          width="150px"
        >
          <s.Text bold color="white">
            Continuar
          </s.Text>
          <s.Icon name="arrowright" fill="white" />
          {isValidateCustomerUserLoading ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.RightButtonAnybank>
      </s.Footer>
    </>
  )
}

export default TbanksCompanyForm
