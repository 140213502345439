import React from 'react'

import errorImg from 'assets/images/anybank-register-error.png'

import * as s from './styles'

const FailAddingBankSlip: React.FC = () => {
  return (
    <s.ErrorWrapper>
      <s.Text margin="32px 0 0 0" type="heading4" bold color="graytheme6">
        Erro no cadastro do boleto em arquivo PDF
      </s.Text>
      <s.Text type="headline" color="graytheme6">
        Um erro aconteceu, tente novamente mais tarde. Caso o problema persista,
        entre em contato com o suporte.
      </s.Text>
      <s.ImageErrorWrapper>
        <img src={errorImg} alt="Imagem de Erro" />
      </s.ImageErrorWrapper>
    </s.ErrorWrapper>
  )
}

export default FailAddingBankSlip
