import React from 'react'

import * as s from '../styles'
import {useTheme} from 'styled-components'
import {get, baseURLAnybankFees} from '../../../services/api'

interface IProps {
  isFrom: string,
  feeTaxes?: []
}

const WelcomeStep: React.FC<IProps> = ({isFrom, feeTaxes}) => {
  const theme = useTheme()

  return (
    <>
      <s.Text type="headline" bold color="graytheme6">
        Receba pagamentos por Pix em qualquer instituição bancária através de
        nosso parceiro
      </s.Text>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="monitoroutline"/>
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Processo simplificado</s.Text>
          <s.Text>
            O processo é feito diretamente no Painel, sem burocracia, e leva
            poucos minutos.
          </s.Text>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="payments"/>
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>
            {feeTaxes[isFrom]
              ? `Taxa fixa de  R$ ${feeTaxes[isFrom].fee_value} sem supresas`
              : 'Taxa fixa sem supresas'}
          </s.Text>
          <s.Text>
            {feeTaxes[isFrom]
              ? `Uma taxa fixa de R$ ${feeTaxes[isFrom].fee_value} é cobrada, automaticamente, apenas pelas transações liquidadas, sem taxas extras.`
              : `Uma taxa fixa é cobrada, automaticamente, apenas pelas transações liquidadas, sem taxas extras. Consulte seu sistema de PDV ou a ${theme.domain} para saber mais.`}
          </s.Text>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="bank"/>
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Receba em qualquer banco</s.Text>
          <s.Text>
            Receba o total dos valores pagos no dia, até às 23:59, em uma conta
            bancária de sua preferência.
          </s.Text>
          <ul>
            <li>
              <s.Text>
                Essa conta de destino precisa ser uma conta Pessoa Jurídica de
                um CNPJ já cadastrado{' '}
                {theme.domain === 'Shipay' ? 'na Shipay' : 'no Conexão Itaú'}.
              </s.Text>
            </li>
            <li>
              <s.Text></s.Text>
              <s.Text>
                Essa conta de destino deve pertencer a uma instituição
                financeira que faça parte do
              </s.Text>
              <s.ButtonLink
                href="https://www.bcb.gov.br/estabilidadefinanceira/participantespix"
                color="maincolor"
                target="_blank"
              >
                Sistema de Pagamentos Instantâneos do Banco Central.
              </s.ButtonLink>
            </li>
          </ul>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="pixiconoutline"/>
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Conta de Passagem</s.Text>
          <s.Text>
            Para aproveitar essas vantagens e receber Pix em qualquer banco, é
            necessário abrir uma conta de passagem
            {theme.domain === 'Shipay'
              ? isFrom === 'original'
                ? ' no Original. A '
                : ' no TbankS. A '
              : '. Essa '}
            conta de passagem servirá como intermediadora para as transações,
            recebendo pagamentos por pix e transferindo-os para uma conta
            bancária de sua preferência no mesmo dia.
          </s.Text>
          <ul>
            <li>
              <s.Text>
                Sem custo de abertura ou manutenção da conta, a taxa fixa é
                apenas por transação, e é descontada automaticamente.
              </s.Text>
            </li>
            <li>
              <s.Text>
                Esta não é uma conta corrente, ela serve apenas como uma conta
                de portabilidade para os pagamentos.
              </s.Text>
            </li>
          </ul>
        </s.TextWrapper>
      </s.InfoBox>
    </>
  )
}

export default WelcomeStep
