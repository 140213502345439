import styled, { css } from 'styled-components'
import ModalCmp from 'components/Modal'
import IconCmp from 'components/IconExporter'
import InputTextCmp from 'components/Inputs/Text'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import LoadingCmp from 'components/Loading'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/styles'
import SelectCmp from 'components/Inputs/Select'

interface ModalProps {
  showScroll?: boolean
}

export const Modal = styled(ModalCmp)<ModalProps>`
  header {
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
    padding: 32px;
    h5 {
      color: ${({ theme }) => theme.colors.graytheme6};
    }
  }

  ${ModalContainer} {
    padding: 0px;
  }

  ${ChildrenWrapper} {
    padding: 0px;
    overflow-y: hidden;
    ${(props) =>
      props.showScroll
        ? css`
            margin-right: 40px;
            overflow-y: scroll;
          `
        : css`
            margin-right: 0px;
          `};

    ::-webkit-scrollbar-track {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
`

export const ModalContentItauAnybank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  margin: 32px;
`

export const ModalContentDefault = styled.div`
  position: relative;
  width: 520px;
  height: 592px;
  margin: 32px 36px 32px 32px;

  @media screen and (max-width: 1440px) {
    height: 480px;
  }
`

export const ModalContentItau = styled.div`
  position: relative;
  width: 520px;
  height: 592px;
  margin: 32px 36px 32px 32px;

  @media screen and (max-width: 1440px) {
    height: 480px;
  }
`

export const ModalContentBB = styled.div`
  position: relative;
  width: 520px;
  height: 592px;
  margin: 32px 36px 32px 32px;

  @media screen and (max-width: 1440px) {
    height: 480px;
  }
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: 532px;
  max-height: 624px;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    /* margin-top: 32px; */
    margin-bottom: 32px;
  }
  @media screen and (max-width: 1440px) {
    overflow-y: auto;
    max-height: 516px;
  }
`

export const Select = styled(SelectCmp)`
  div > div > p {
    line-height: 21px;
    font-size: 18px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.black : theme.colors.graytheme6};
    font-weight: normal;
  }
  > div > span > p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500px;
    color: ${({ theme }) => theme.colors.cancelledStatusTextColor};
  }
`

interface SectionsProps {
  showScroll?: boolean
}

export const SectionsEditFormWrapper = styled.div<SectionsProps>`
  ${(props) =>
    props.showScroll
      ? css`
          padding-right: 32px;
        `
      : css``};
`

export const Text = styled(TextCmp)``

export const ProviderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  gap: 10px;
`

export const SectionsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 780px;
  flex-direction: column;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0;

  :first-child {
    padding-top: 0px;
  }

  :last-child {
    padding-bottom: 32px;
  }
`

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
`
export const ProviderLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`

export const TagWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 304px;
`

export const TransactionTypeTag = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.graytheme10};
  border-radius: 8px;
  padding: 4px 8px;

  svg {
    height: 9px;
    width: 9px;
    margin-right: 4px;
  }
`

export const AnybankTag = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.graytheme10};
  border-radius: 8px;
`

export const TextTag = styled(TextCmp)`
  padding: 4px 8px;
`

export const Icon = styled(IconCmp)`
  display: inline;
`
export const TransactionTagWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export const Loading = styled(LoadingCmp)``

export const SectionLineDivider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`
export const InputText = styled(InputTextCmp)``

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`

export const EditFeeWraper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const ActivationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 82px;
`

export const Button = styled(ButtonCmp)`
  height: 40px;
  svg {
    margin-right: 8px;
  }
`
export const AddBankSlipButton = styled(ButtonCmp)`
  height: 40px;
  width: 120px;
  span {
    padding-left: 0px;
  }
  svg {
    margin-right: 8px;
  }
`

export const AddBankSlipButtonEdit = styled.button`
  display: flex;
  border: none;
  background: transparent;
  :hover {
    cursor: pointer;
  }
`

export const CancelButton = styled(ButtonCmp)`
  height: 40px;
  svg {
    margin-right: 8px;
  }
  :disabled {
    background-color: transparent;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const WarningCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  width: 100%;

  p {
    padding: 8px;
  }
`

export const IdContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const IdWrapper = styled.div`
  display: flex;
  gap: 8px;
  ${({ theme }) => theme.assets.orders.columns.uuidContainer};
  padding-left: 0px;
`

export const TooltipText = styled.span`
  visibility: hidden;
  width: 320px;
  background-color: ${({ theme }) => theme.colors.graytheme6};
  color: white;
  max-height: 96px;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 10;
  bottom: -24px;
`

export const Hover = styled.div`
  position: relative;
  :hover {
    cursor: help;
    ${TooltipText} {
      visibility: visible;
    }
  }
`

export const Align = styled.div`
  display: flex;
  align-items: baseline;
`

export const ModalSplits = styled(ModalCmp)`
  header {
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
    padding: 32px;
    h5 {
      color: ${({ theme }) => theme.colors.graytheme6};
    }
  }

  ${ModalContainer} {
    padding: 0;
  }
`

export const ModalContentSplits = styled.div`
  width: 550px;
  padding: 0px 16px;
`

export const InfoBox = styled.div<{
  error: boolean
  margin?: string
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};
  ${({ error, theme, margin }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
          flex-direction: column;
          margin: ${margin};
        `
      : css``}
`

export const SectionsWithButton = styled(Sections)`
  height: calc(100% - 105px);
  overflow: auto;
  padding-right: 32px;
`

export const ProviderContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

interface ButtonWrapperProps {
  margin?: string
}

export const Div = styled.div``

export const PreviousNextButtonContainer = styled.div<ButtonWrapperProps>`
  position: absolute;
  width: 100%;
  bottom: 0;
  background: white;

  div:first-child {
    width: calc(100%+32px);
    height: 1px;
    margin: 0px -32px 32px -32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const PreviousNextButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: ${({ margin }) => margin};
`
export const SeeMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SeeMoreButton = styled.button`
  display: inline;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.maincolor};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  :hover {
    cursor: pointer;
  }
`
export const LoadingWrapper = styled.div`
  margin-top: 64px;
  overflow: hidden;
`

export const AnybankLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  overflow: hidden;
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 134px;
  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media screen and (max-width: 1440px) {
    margin-top: 84px;
  }
`

export const AnybankLoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media screen and (max-width: 1440px) {
    margin-top: 84px;
  }
`

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 75px);
  gap: 16px;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`
interface ImageWrapperProps {
  width?: number
  height?: number
}

export const ImageErrorWrapper = styled.div<ImageWrapperProps>`
  margin: auto auto 16px auto;

  img {
    width: ${({ width }) => (width ? `${width}px` : '491px')};
    height: ${({ height }) => (height ? `${height}px` : '248px')};
  }
`

export const Image = styled.img``
