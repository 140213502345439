import React from 'react'
import * as s from './styles'
import {
  applyBankAccountItauMask,
  applyCnpjMask,
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyPhoneMask,
} from 'helpers/masks'
import { dict as dictIcons } from 'components/IconExporter'
import { HelperMessage } from '../components/HelperMessage'
import { useGetGeoAddress } from 'hooks/geo'
import { removeAccents } from 'helpers'

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return <></>
}

const BankSlipRecipientForm = ({
  bankSlipRecipientConfig,
  formState,
  formErrorState,
  infoErrorState,
}) => {
  const [form, setForm] = formState
  const [formErrors, setFormErrors] = formErrorState
  const [infoError, setInfoError] = infoErrorState
  const { address, isLoading, loadGeoAddress } = useGetGeoAddress()

  React.useEffect(() => {
    if (address.city) {
      setForm({
        ...form,
        zip_code: address?.postal_code,
        city_name: removeAccents(address?.city),
        state_name: address?.state,
        street_name: removeAccents(address?.street),
        neighborhood: removeAccents(address?.neighborhood),
        street_number: '',
        complementary: '',
      })

      setFormErrors({
        ...formErrors,
        zip_code: '',
        city_name: '',
        state_name: '',
        street_name: '',
        neighborhood: '',
      })

      // resetGeoAddress()
    }
  }, [address])

  const applyFieldMask = (field, value) => {
    if (field.validators) {
      if (field.validators.includes('isValidCpfCnpj'))
        return applyCpfCnpjMask(value)
      if (field.validators.includes('isValidCnpj')) return applyCnpjMask(value)
      if (field.validators.includes('isValidPhone'))
        return applyPhoneMask(value)
      if (field.validators.includes('isNumberOnly'))
        return applyNumberOnlyMask(value)
      if (field.validators.includes('isBankAccountItau'))
        return applyBankAccountItauMask(value)
    }
    return value
  }

  const disableFieldsBasedOnCEP = (fieldKey) => {
    const fieldMap = new Map<string, string>([
      ['street_name', 'street'],
      ['neighborhood', 'neighborhood'],
      ['city_name', 'city'],
      ['state_name', 'state'],
    ])

    const addressKey = fieldMap.get(fieldKey)
    if (fieldMap.has(fieldKey) && address[addressKey] !== '') {
      return true
    }
    return false
  }

  const InputElement: React.FC<{
    key: string
    friendlyName: string
    fieldHelper?: string
    valueType: string
    value?: string
    options?: Array<string> | Array<{ selectable: string; visible: string }>
    inputType: string
    placeholder: string
    maxLength: number
    validators: string[]
  }> = (field) => {
    switch (field.inputType) {
      case 'text':
        return (
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
            >
              {field.friendlyName}
            </s.Text>
            <s.InputText
              disabled={disableFieldsBasedOnCEP(field.key)}
              placeholder={field.placeholder}
              maxLength={field.maxLength ?? undefined}
              testId={`${field.key}-test`}
              width={260}
              value={form[field.key] || ''}
              onBlur={() => {
                if (
                  form?.zip_code?.replace(/-/g, '').length === 8 &&
                  form?.zip_code?.replace(/-/g, '') !== address?.postal_code
                ) {
                  loadGeoAddress(form?.zip_code?.replace(/-/g, ''))
                }
              }}
              onChange={(e) => {
                setForm({
                  ...form,
                  [field.key]: applyFieldMask(field, e.target.value),
                })
                if (formErrors[field.key]) {
                  setFormErrors({ ...formErrors, [field.key]: '' })
                }
              }}
              error={
                formErrors[field.key]
                  ? { message: formErrors[field.key] }
                  : false
              }
              suffix={
                isLoading && field.key === 'zip_code' ? (
                  <s.Loading type="spinner" color="maincolor" />
                ) : formErrors[field.key] ? (
                  <s.Icon name="alertcirclefilled" fill="lightred2" />
                ) : (
                  <></>
                )
              }
            />
          </s.SectionLine>
        )

      default:
        return <></>
    }
  }

  return (
    <s.Sections data-testid="content-on-bank-slip-recipient-form">
      <s.SectionsRegisterFormWrapper>
        <>
          <React.Fragment key={bankSlipRecipientConfig?.key}>
            <s.Section>
              {infoError.title ? (
                <InfoBox error={infoError} />
              ) : (
                <s.ListBoxOutline>
                  <s.Text type="paragraph" color="graytheme6">
                    {bankSlipRecipientConfig.disclaimer.value}
                  </s.Text>
                </s.ListBoxOutline>
              )}
              <s.WrapperSectionHeader style={{ marginTop: '32px' }}>
                <s.Align>
                  <s.Icon
                    name={
                      bankSlipRecipientConfig?.icon as keyof typeof dictIcons
                    }
                    fill="graytheme6"
                  />
                  <s.Text
                    margin="0 0 0 8px"
                    type="headline"
                    fontWeight={600}
                    color="graytheme6"
                  >
                    {bankSlipRecipientConfig?.friendlyName}
                  </s.Text>
                </s.Align>
              </s.WrapperSectionHeader>
              {bankSlipRecipientConfig?.sectionHelper?.text ? (
                <HelperMessage
                  testId={`${bankSlipRecipientConfig.key}-help-message`}
                  text={bankSlipRecipientConfig.sectionHelper.text}
                  seeMoreText={
                    bankSlipRecipientConfig.sectionHelper.showMoreText
                  }
                />
              ) : (
                <></>
              )}
              {bankSlipRecipientConfig.fields.map((field) => {
                return (
                  <React.Fragment key={field.key}>
                    {InputElement({
                      friendlyName: field.friendlyName,
                      fieldHelper: field.fieldHelper,
                      inputType: field.inputType,
                      key: field.key,
                      value: field.value,
                      options: field.options,
                      maxLength: field.maxLength,
                      placeholder: field.placeholder,
                      valueType: field.valueType,
                      validators: field.validators,
                    })}
                  </React.Fragment>
                )
              })}
            </s.Section>
          </React.Fragment>
        </>
      </s.SectionsRegisterFormWrapper>
    </s.Sections>
  )
}
export default BankSlipRecipientForm
