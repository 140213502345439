import React, { SetStateAction } from 'react'

import * as s from './styles'
import { bffv2URLBankSlipRecipient, post } from 'services/api'
import { showToast } from 'components/Toast'
import SuccessAddingBankSlip from './SuccessAddingBankSlip'
import { HelperMessage } from './components/HelperMessage'
import { useGetGeoAddress } from 'hooks/geo'

import { isNotEmpty } from 'helpers/validators'
import {
  applyBankAccountItauMask,
  applyCEPMask,
  applyCnpjMask,
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyPhoneMask,
} from 'helpers/masks'
import { BanksInstituitions, Store } from 'hooks/useGetWalletSettingModalConfig'
import { removeAccents } from 'helpers'
import FailAddingBankSlip from './FailedAddingBankSlip'
import { AxiosError } from 'axios'

interface BankSlipRecipientFields {
  key: string
  maxLength?: number
  friendlyName: string
  fieldHelper?: string
  valueType: string
  value?: string
  inputType: string
  placeholder?: string
  validators?: Array<string>
  info?: string
}

interface SectionHelperContent {
  text: string
  url?: string
}

interface IProps {
  bankSlipSettingsId: string
  walletFriendlyName: string
  pspProvider: string
  walletIcon: string
  name: string
  sectionHelper?: SectionHelperContent
  bankSlipRecipientFields?: Array<BankSlipRecipientFields>
  hasBankSlip: {
    settings: boolean
    recipient: boolean
  }
  setHasBankSlip: React.Dispatch<
    SetStateAction<{
      settings: boolean
      recipient: boolean
    }>
  >
  goBack: (isFromSuccess: boolean) => void
  handleClose?: () => void
}

interface Field {
  name: string
  message: string
}

interface FormInfoError {
  title: string
  message: React.ReactElement
}

interface Fields {
  fields: Field[]
}

interface SectionErrorField {
  property: string
  message: string
}

interface ErrorFromDTO {
  message: [SectionErrorField[]]
  error: string
  statusCode: number
}

type ErrorFromBFF = Fields | ErrorFromDTO

interface FormData {
  name: string
  cnpj: string
  bank_branch: string
  bank_account: string
  zip_code: string
  street_name: string
  street_number: string
  complementary?: string
  neighborhood: string
  city_name: string
  state_name: string
}

const INITIAL_INFO_ERROR_DATA: FormInfoError = { title: '', message: <></> }

const INITIAL_FORM_ERROR_DATA: FormData = {
  name: '',
  cnpj: '',
  bank_branch: '',
  bank_account: '',
  zip_code: '',
  street_name: '',
  street_number: '',
  neighborhood: '',
  city_name: '',
  state_name: '',
}

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return <></>
}

function removeEmptyKey(form: FormData) {
  return Object.keys(form).forEach(
    (key) => form[key] === '' && delete form[key]
  )
}

const AddBankSlipDefault: React.FC<IProps> = ({
  bankSlipSettingsId,
  pspProvider,
  name,
  walletIcon,
  walletFriendlyName,
  bankSlipRecipientFields,
  hasBankSlip,
  setHasBankSlip,
  goBack,
  sectionHelper,
  handleClose,
}) => {
  const [formErrors, setFormErrors] = React.useState<FormData>(
    INITIAL_FORM_ERROR_DATA
  )
  const [infoError, setInfoError] = React.useState<FormInfoError>(
    INITIAL_INFO_ERROR_DATA
  )

  const [form, setForm] = React.useState<FormData>({
    name: '',
    cnpj: '',
    bank_branch: '',
    bank_account: '',
    zip_code: '',
    street_name: '',
    street_number: '',
    complementary: '',
    neighborhood: '',
    city_name: '',
    state_name: '',
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const [responseStatus, setResponseStatus] = React.useState({
    apiError: false,
    apiSuccess: false,
  })
  const {
    address,
    isLoading: isLoadingAddress,
    loadGeoAddress,
  } = useGetGeoAddress()

  const infoErrorHandler = (type, errors) => {
    let filteredErrors = {} as FormData
    let friendlyNames: { [key: string]: string } = {}
    renamedBankSlipRecipientFields.forEach((field) => {
      filteredErrors[field.key] = errors[field.key]
      friendlyNames[field.key] = field.friendlyName
    })

    const { complementary, ...requiredRecipientFields } = filteredErrors

    setFormErrors(requiredRecipientFields)

    const fieldsTranslated = Object.entries(filteredErrors)
      .map((error) => (error[1] ? friendlyNames[error[0]] : undefined))
      .filter((error) => error)

    let requiredFields = ''

    if (fieldsTranslated.length > 2) {
      requiredFields = `${fieldsTranslated.slice(0, -1).join(', ')} e ${
        fieldsTranslated.slice(-1)[0]
      }`
    } else if (fieldsTranslated.length === 2) {
      requiredFields = fieldsTranslated.join(' e ')
    } else {
      requiredFields = fieldsTranslated[0]
    }

    if (type === 'emptyFields') {
      setInfoError({
        title: 'Campo(s) obrigatório(s) não preenchido(s)',
        message: (
          <>
            Preencha o(s) campo(s) de <u>{requiredFields}</u> para prosseguir.
          </>
        ),
      })
    }

    if (type === 'invalidFields') {
      if (fieldsTranslated.length >= 2) {
        setInfoError({
          title: 'Informações inválidas.',
          message: (
            <>
              Confira se as informações nos campos de <u>{requiredFields}</u>,
              estão corretas e tente novamente.
            </>
          ),
        })
      } else {
        setInfoError({
          title: 'Informação inválida.',
          message: (
            <>
              Confira se a informação no campo de <u>{requiredFields}</u>, está
              correta e tente novamente.
            </>
          ),
        })
      }
    }
  }

  const renamedBankSlipRecipientFields = React.useMemo(() => {
    if (!bankSlipRecipientFields) return []
    return bankSlipRecipientFields.map((field) => {
      let newKey = field.key
      if (field.key === 'recipient_name') {
        newKey = 'name'
      } else if (field.key === 'recipient_bank_branch') {
        newKey = 'bank_branch'
      } else if (field.key === 'recipient_bank_account') {
        newKey = 'bank_account'
      }
      return { ...field, key: newKey }
    })
  }, [bankSlipRecipientFields])

  const addBankSlip = () => {
    const errors = { ...formErrors }

    for (const [key, value] of Object.entries(form)) {
      if (
        !errors[key] &&
        renamedBankSlipRecipientFields.some(
          (field) => field.key === key && key !== 'complementary'
        )
      )
        errors[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }

    const errList = Object.values(errors).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      removeEmptyKey(form)
      const payload = {
        bank_slip_settings_id: bankSlipSettingsId,
        psp_provider: pspProvider,
        bank_slip_recipient: {
          ...form,
        },
      }
      setIsLoading(true)
      post(bffv2URLBankSlipRecipient, payload)
        .then(() => {
          setResponseStatus({
            apiError: false,
            apiSuccess: true,
          })
          setHasBankSlip({
            ...hasBankSlip,
            recipient: true,
          })
        })
        .catch((error: AxiosError) => {
          const response = (error.response?.data as ErrorFromBFF) || undefined
          const statusCode = error.response?.status || undefined
          const fieldsErrorsFromResponse = {}

          if (statusCode < 500) {
            try {
              if ('fields' in response && response.fields?.length > 0) {
                for (let obj of response.fields) {
                  fieldsErrorsFromResponse[obj.name] = obj.message
                }
              } else if (
                'message' in response &&
                response.message?.length > 0
              ) {
                for (let section of response.message) {
                  for (let input of section) {
                    //BFF errors that comes from invalid requirements from DTO are like 'nameofthesection.field'
                    fieldsErrorsFromResponse[input.property.split('.')[1]] = ' '
                  }
                }
              }
              infoErrorHandler('invalidFields', fieldsErrorsFromResponse)
            } catch {
              setResponseStatus({
                apiError: true,
                apiSuccess: false,
              })
            }
          } else {
            setResponseStatus({
              apiError: true,
              apiSuccess: false,
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      infoErrorHandler('emptyFields', errors)
      setIsLoading(false)
    }
  }

  const applyFieldMask = (field, value) => {
    if (field.validators) {
      if (field.validators.includes('isValidCpfCnpj'))
        return applyCpfCnpjMask(value)
      if (field.validators.includes('isValidCnpj')) return applyCnpjMask(value)
      if (field.validators.includes('isValidPhone'))
        return applyPhoneMask(value)
      if (field.validators.includes('isNumberOnly'))
        return applyNumberOnlyMask(value)
      if (field.validators.includes('isBankAccountItau'))
        return applyBankAccountItauMask(value)
    }
    return value
  }

  const disableFieldHandler = (key: string, value: string) => {
    if (!value) return false
    return key === 'neighborhood' || key === 'city_name' || key === 'state_name'
  }

  React.useEffect(() => {
    if (address.city) {
      setForm({
        ...form,
        zip_code: address?.postal_code,
        city_name: removeAccents(address?.city),
        state_name: address?.state,
        street_name: removeAccents(address?.street),
        neighborhood: removeAccents(address?.neighborhood),
        street_number: '',
        complementary: '',
      })

      setFormErrors({
        ...formErrors,
        zip_code: '',
        city_name: '',
        state_name: '',
        street_name: '',
        neighborhood: '',
      })
    }
  }, [address])

  const InputElement: React.FC<{
    key: string
    friendlyName: string
    fieldHelper?: string
    valueType: string
    value: string
    options?:
      | Array<string>
      | Array<BanksInstituitions>
      | Array<Store>
      | Array<{ selectable: string; visible: string }>
    inputType: string
    placeholder: string
    maxLength: number
    validators: string[]
  }> = (field) => {
    switch (field.inputType) {
      case 'text':
        return (
          <>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
              >
                {field.friendlyName}
              </s.Text>
              <>
                {field.key === 'zip_code' ? (
                  <s.InputText
                    placeholder={field.placeholder}
                    maxLength={field.maxLength ?? undefined}
                    testId={`${field.key}-test`}
                    width={260}
                    value={applyCEPMask(form[field.key]) || ''}
                    onBlur={() => {
                      if (
                        form?.zip_code?.replace(/-/g, '').length === 8 &&
                        form?.zip_code?.replace(/-/g, '') !==
                          address?.postal_code
                      ) {
                        loadGeoAddress(form?.zip_code?.replace(/-/g, ''))
                      }
                    }}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [field.key]: applyFieldMask(field, e.target.value),
                      })
                      if (formErrors[field.key]) {
                        setFormErrors({ ...formErrors, [field.key]: '' })
                      }
                    }}
                    error={
                      formErrors[field.key]
                        ? { message: formErrors[field.key] }
                        : false
                    }
                    suffix={
                      isLoadingAddress ? (
                        <s.Loading type="spinner" color="maincolor" />
                      ) : formErrors.zip_code ? (
                        <s.Icon name="alertcirclefilled" fill="lightred2" />
                      ) : (
                        <></>
                      )
                    }
                  />
                ) : (
                  <s.InputText
                    placeholder={field.placeholder}
                    maxLength={field.maxLength ?? undefined}
                    testId={`${field.key}-test`}
                    width={260}
                    value={form[field.key] || ''}
                    disabled={disableFieldHandler(field.key, form[field.key])}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [field.key]: applyFieldMask(field, e.target.value),
                      })
                      if (formErrors[field.key]) {
                        setFormErrors({ ...formErrors, [field.key]: '' })
                      }
                    }}
                    error={
                      formErrors[field.key]
                        ? { message: formErrors[field.key] }
                        : false
                    }
                    suffix={
                      formErrors[field.key] ? (
                        <s.Icon name="alertcirclefilled" fill="lightred2" />
                      ) : (
                        <></>
                      )
                    }
                  />
                )}
              </>
            </s.SectionLine>
            {field.key === 'name' ? (
              <HelperMessage
                testId={`${field.key}-help-message`}
                text={field.fieldHelper}
                error={!!formErrors[field.key]}
                handleClose={handleClose}
              />
            ) : (
              <></>
            )}
          </>
        )

      default:
        return <></>
    }
  }

  const handleModalContent = () => {
    if (responseStatus.apiSuccess) {
      return (
        <>
          <SuccessAddingBankSlip
            walletFriendlyName={walletFriendlyName}
            feature="Boleto em arquivo PDF"
          />
          <s.PreviousNextButtonContainer>
            <s.Div></s.Div>
            <s.PreviousNextButtonWrapper>
              <s.Button
                data-testid="go-to-stores-button"
                onClick={() => goBack(true)}
                disabled={false}
                width="100%"
                height="42px"
                color="maincolor"
              >
                <s.Text color="white">
                  Voltar para Configurações da conta
                </s.Text>
              </s.Button>
            </s.PreviousNextButtonWrapper>
          </s.PreviousNextButtonContainer>
        </>
      )
    }

    if (responseStatus.apiError) {
      return (
        <>
          <FailAddingBankSlip />
          <s.PreviousNextButtonContainer>
            <s.Div></s.Div>
            <s.PreviousNextButtonWrapper>
              <s.Button
                data-testid="back-button"
                onClick={() => {
                  goBack(true)
                }}
                width="100%"
                height="42px"
                color="whitedetailed"
              >
                <s.Text color="maincolor" type="headline">
                  Voltar
                </s.Text>
              </s.Button>
            </s.PreviousNextButtonWrapper>
          </s.PreviousNextButtonContainer>
        </>
      )
    }

    return (
      <>
        <s.SectionsWithButton>
          <InfoBox error={infoError} />
          <s.Section data-testid="provider-section">
            <s.ProviderContainer>
              <s.Text
                fontWeight={600}
                type="headline"
                margin="0 118px 0 0"
                color="graytheme8"
              >
                Provedor Pix
              </s.Text>
              <img src={walletIcon} width={34} />
              <s.Text
                bold
                type="headline"
                margin="0 0 0 8px"
                color="graytheme8"
              >
                {walletFriendlyName}
              </s.Text>
            </s.ProviderContainer>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                Apelido da conta
              </s.Text>
              <s.InputText
                testId="name-input"
                width={260}
                value={name}
                disabled={true}
                onChange={(e) => {}}
              />
            </s.SectionLine>
          </s.Section>
          <s.Line />
          <s.Section data-testid="create-bank-slip-section">
            <s.SeeMoreWrapper>
              <s.TitleWrapper>
                <s.Icon name="docs" fill="gray1" />
                <s.Text type="headline" color="graytheme6">
                  Boleto em arquivo PDF
                </s.Text>
              </s.TitleWrapper>
              {sectionHelper?.text ? (
                <s.Text
                  data-testid="bank_slip_recipient-help-section-text"
                  margin="8px 0 0 2px"
                  type="tag"
                >
                  {sectionHelper.text}
                </s.Text>
              ) : (
                <></>
              )}
            </s.SeeMoreWrapper>
            {renamedBankSlipRecipientFields.map((field) => {
              return (
                <React.Fragment key={field.key}>
                  {InputElement({
                    friendlyName: field.friendlyName,
                    fieldHelper: field.fieldHelper,
                    inputType: field.inputType,
                    key: field.key,
                    value: field.value,
                    maxLength: field.maxLength,
                    placeholder: field.placeholder,
                    valueType: field.valueType,
                    validators: field.validators,
                  })}
                </React.Fragment>
              )
            })}
          </s.Section>
          <s.Text type="tag">
            Caso deseje gerar boletos no nome de mais de uma empresa, é
            necessário cadastrar outras contas Pix com a funcionalidade de
            boleto híbrido, sendo uma para cada empresa.
          </s.Text>
        </s.SectionsWithButton>
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                goBack(false)
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Text color="maincolor" type="headline">
                Cancelar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="add-bank-slip-button"
              width="304px"
              height="42px"
              onClick={() => {
                addBankSlip()
              }}
              disabled={isLoading}
            >
              {isLoading ? <s.Loading color="white" type="spinner" /> : <></>}
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Adicionar
              </s.Text>
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      </>
    )
  }

  return <>{handleModalContent()}</>
}

export default AddBankSlipDefault
